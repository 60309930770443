import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import { Typography, Divider } from '@mui/material';

const DocList = props => {
    const { store, dateBegin, dateEnd, setUser } = props
    const columns = [
      { field: 'id', headerName: 'ID', width: 30 },
      { field: 'prodejka', headerName: 'Dokument', width: 130 },
      { field: 'polozka', headerName: 'Artikl', width: 250 },
      { field: 'uctenka', headerName: 'Učtenka', width: 150, },
      { field: 'datum', headerName: 'Datum', width: 110, },
      { field: 'cena', headerName: 'Cena', width: 100, },
    ];
    const [tableData, setTableData] = useState([])
    const [itemsSum, setItemsSum] = useState(null)
    const [selectionModel, setSelectionModel] = React.useState([])
  
    const selectedItems = selection => {
      setSelectionModel(selection)
      let sumSelected = 0
      tableData.forEach(item => {
        if (selection.includes(item.id)) { sumSelected += item.cena }
      })
      selection.length === 0 ? setItemsSum(null) : setItemsSum(sumSelected)
    }
  
    useEffect( () => { 
        async function fetchData () {
          if (parseInt(dateBegin.format('YYYY')) > 2016) {
            const res = await fetch('/api/list', { 
              method: "POST",
              body: JSON.stringify({ 
                store, 
                dateBegin: dateBegin.format('YYYY-MM-DD'), 
                dateEnd: dateEnd.format('YYYY-MM-DD'), 
              }),
              headers: { "Content-Type": "application/json" },
            })
            if (res.status == 200) {
              setItemsSum(null)
              setSelectionModel([])
              setTableData(await res.json())
            }
            if (res.status == 401) setUser(undefined)
          }
        }
        fetchData()
    }, [store, dateEnd, dateBegin])
  
    return (
      <div>
        <Paper elevation={2} sx={{ p:1 }}>
        { itemsSum !== null &&
            <Typography ml={1} mb={1}>
                Součet: {Math.round(itemsSum)} Kč
            </Typography>
          }
          <DataGrid
            autoHeight
            rows={tableData}
            columns={columns}
            initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
            }}
            pageSizeOptions={[10, 20]}
            checkboxSelection
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange = {selectedItems}
          />
        </Paper>
      </div>
    )
  }

  export default DocList