import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import debounce from 'lodash/debounce';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';

const StockTable = ({varList}) => {
  const {varHeader, stockList} = varList
  return (
      <TableContainer>
      <Table size="small" aria-label="Sizes table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {varHeader.map( (e, idx)  => (
                <TableCell key={"size" + idx}>{e.size}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
          <TableCell></TableCell>
            {varHeader.map( (e, idx) => (
                <TableCell key={"price" + idx}>{e.price}</TableCell>
              ))}
          </TableRow>
          {stockList.map((e, idx) => (
            <TableRow key={e.store + idx}>
              <TableCell>{e.store}</TableCell>
              {e.sizeQuantity.map((q, qidx) => (
                <TableCell key={"q" + idx + qidx}>{q}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    
  )
}

const ProdInput = ({setProdId, setPicLink}) => {

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [products, setProducts] = React.useState([]);

  const options = products.map(e => [e.department, e.brand, e.prodId].join(" "))
  
  const getProds = React.useMemo(
    () => debounce((request, callback) => {
        fetch('/api/prodid', { 
            method: "POST",
            body: JSON.stringify(request),
            headers: { "Content-Type": "application/json" },
          })
          .then(res => res.json())
          .then(callback)
    }, 500), []
  );

  React.useEffect(() => {
    let active = true
    if (inputValue === '') {
      //setOptions(value ? [value] : [])
      return undefined
    }

    getProds({ value: inputValue }, results => {
      if (active) {
        //let newOptions = [];
        //if (value) { newOptions = [value] }
        if (results) {
          /*
          if (results.length === 1) {
            setValue(results[0]);
            setOptions([]);
            return undefined;
          }
          */
          //newOptions = [...newOptions, ...results];
          setProducts(results)
        }
        //setOptions(newOptions);
      }
    })

    return () => { active = false }

  }, [value, inputValue, getProds]);
  
  return (
    <Autocomplete
      id="product-search"
      size={'small'}
      autoComplete
      includeInputInList
      filterSelectedOptions
      filterOptions={(x) => x}
      value={value}
      options={options}
      onChange={(event, newValue) => { 
        const valueIndex = options.findIndex(e => e === newValue)
        if (valueIndex !== -1) {
          const { prodId, picLink } = products[valueIndex]
          setProdId(prodId)
          setPicLink(picLink)
        } else {
          setProdId(null)
          setPicLink(null)
        }
        setValue(newValue)
      }}
      onInputChange={(event, newInputValue) => { 
        setInputValue(newInputValue)
      }}
      renderInput={ params => (<TextField {...params} label="Product name" fullWidth />)}
    />
  )
}

const ProdSearch = () => {
  const [prodId, setProdId] = React.useState(null)
  const [picLink, setPicLink] = React.useState(null)
  const [varList, setVarList] = React.useState({varHeader: [], stockList: []})

  React.useEffect(() => {
    if (prodId) {
      fetch('/api/variants', { 
        method: "POST",
        body: JSON.stringify({prodId}),
        headers: { "Content-Type": "application/json" },
      })
      .then(res => res.json())
      .then(res => {
        setVarList(res)})
    }
  }, [prodId])

  return (
    <Paper elevation={2} sx={{ p:2 }}>
    <Grid container spacing={2} rowSpacing={1}>
      <Grid xs={9}>
        <ProdInput setProdId={setProdId} setPicLink= {setPicLink}/>
        {prodId && <StockTable varList={varList} />}
      </Grid>
      {picLink && <Grid xs={3} 
        style={{backgroundImage: `url(${picLink})`,   
        backgroundSize:'contain',
        backgroundRepeat: 'no-repeat',
      }}/>}
    </Grid>
    </Paper>
  )
}

export default ProdSearch