import React, {useState, useEffect} from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import ProdSearch from './components/ProdSearch';
import DocList from './components/DocList';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

//dev localhost:3000
//const clientId = "383098064574-d2t1572ri4lfp6ujucfp4pv0ck5r9n6p.apps.googleusercontent.com"
//prod pt.primatrend.cz
const clientId = "383098064574-p0oilmd627cl7th7424nigj93hn2h2m1.apps.googleusercontent.com"

const App = () => {
  const currentDate = dayjs()
  const pastDate = dayjs().subtract(1, 'month').startOf('month')
  const [endDate, setEndDate] = useState(currentDate)
  const [beginDate, setBeginDate] = useState(pastDate)

  const [user, setUser] = useState(null)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [store, setStore] = useState(1)

  useEffect( () => {
    fetch('/api/user')
    .then( res => {
      if (!res.ok) { throw new Error("Server respond:" + res.status)}
      if (res.status === 200) {
        res.json()
        .then(res => { console.log(res); setUser(res) })
      }
    })
    .catch( err => console.error("There has been a problem with server connection:", err))
  }, [])

  const refreshIdokData = async () => {
    setIsRefreshing(true)
    let uri= '/api/refresh'
    if (store == 3) {uri= '/api/esrrefresh'}
    const res = await fetch(uri, { method: "POST" })
    if (res.status == 401) setUser(undefined)
    if (res.status == 200) console.log(await res.json())
    setIsRefreshing(false)
    setStore(1)
  }

  const responseGoogle = async googleData => {
    const res = await fetch('/api/user', {
        method: "POST",
        body: JSON.stringify({ token: googleData.credential }),
        headers: { "Content-Type": "application/json" }
    })
    if (res.status === 200) setUser(await res.json())
  }
  
  return (
    <>
      <GoogleOAuthProvider clientId={clientId}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box maxWidth='lg' p={1}>
        {user ?
          <Stack spacing={1} direction="column" sx={{maxWidth: 900}} >
            <ProdSearch/>
            {!user.restricted &&
              <>
              <Paper elevation={2} sx={{ p:1 }}>
              <Stack spacing={1} direction="row">
                <FormControl size={'small'}>
                  <InputLabel id='select-store-label'>Prodej</InputLabel>
                  <Select
                    labelId='select-store-label'
                    id='select-store'
                    value={store}
                    label='Prodej'
                    onChange={ e => setStore(e.target.value) }
                  >
                    <MenuItem value={1}>Prima Trend</MenuItem>
                    <MenuItem value={2}>primigistore.cz</MenuItem>
                    <MenuItem value={4}>Primigi Kotva</MenuItem>
                    <MenuItem value={3}>solomio.cz</MenuItem>
                    <MenuItem value={6}>pmg.cz Outlet</MenuItem>
                    <MenuItem value={7}>pmg.cz Kotva</MenuItem>
                  </Select>
                </FormControl>
                <Button variant='contained' onClick={refreshIdokData} disabled={isRefreshing} store={store}>obnovit</Button>
                <DatePicker
                  label='Začátek'
                  value={beginDate}
                  onChange={newDate => setBeginDate(newDate)}
                  slots={{
                    textField: params => <TextField {...params} size= 'small' sx = {{width: 170}} />,
                  }}
                />
                <DatePicker
                  label='Konec'
                  value={endDate}
                  onChange={newDate => setEndDate(newDate)}
                  slots={{
                    textField: params => <TextField {...params} size= 'small' sx = {{width: 170}} />,
                  }}
                />
              </Stack>
              </Paper>
              <DocList store={store} dateBegin={beginDate} dateEnd={endDate} setUser={setUser} />
              </>
            }
          </Stack>
          : <GoogleLogin
              onSuccess={responseGoogle}
              onError={() => { console.log('Login Failed') }}
            />
        }
      </Box>
      </LocalizationProvider>
      </GoogleOAuthProvider>
    </>
  );
}

export default App